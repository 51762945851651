import React from 'react'
import Layout from '../layouts'
import Iframe from 'react-iframe'
import CommonHero from '../components/commonhero/commonhero.js'

const AddListing = () => (
	<Layout head={{ title: 'Add Listing' }}>
		<CommonHero pageTitle="Add Listing" />
		<main className="bg-white">
			<section id="featured-listing" className="bg-white">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<Iframe
								url="https://airtable.com/embed/shr9Y6BHUdgwogCrV?backgroundColor=blue"
								width="100%"
								id="myId"
								className="airtable-embed"
								height="1024px"
								styles={{
									background: 'transparent',
									border: '1px solid #ccc',
									position: 'initial',
									overflowY: 'hidden',
									margin: '70px 0',
								}}
							/>
						</div>
					</div>
				</div>
			</section>
		</main>
	</Layout>
)

export default AddListing
